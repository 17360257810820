<template>
  <div id="overview-course">
    <div v-show="spinner" class="div-companies bg-white content-wrapper">
      <section class="content-header p-1 px-2">
        <div class="div-header container-fluid">
          <div style="border-bottom: 1px solid #0001" class="row mb-2">
            <div class="col-sm-12 d-flex justify-content-between align-items-center flex-wrap">
              <h4 style="color: #0007;" class="mt-3">
                <i class="fas fa-graduation-cap" aria-hidden="true"></i>
                Academy - Visão Geral
              </h4>
              <div>
                <!-- <button @click="deleteCourse()" class="btn btn-sm btn-danger mr-1 ml-0">
                  Excluir Curso
                </button> -->
                <button @click="$router.push(`/admin/academy/edit/${$route.params.id}`)"
                        class="btn btn-sm btn-primary mr-1">
                  Editar
                </button>
                <button @click="$router.push(`/admin/academy/comments/${$route.params.id}`)"
                        class="btn btn-sm btn-outline-primary mr-1">
                  Comentários
                </button>
                <button class="btn btn-sm btn-outline-secondary" @click="$router.push('/admin/academy')">
                  <i class="fa fa-arrow-left mx-1"></i>Voltar
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>


      <div class="container-fluid">
        <!-- visao geral - imagem -->
        <div class="row">
          <div class="w-100 mt-2 ml-2 mr-2 mb-3">
            <div class="col-12 d-flex flex-column image-title">
              <div class="img-fluid image-container">
                <img @load="onLoadImg()" draggable="false"
                      :src="course.img ? image_url + course.img : require('../../../../assets/images/default-cover.png')" :alt="`Banner do curso ${course.title}`"
                     :title="`Banner do curso ${course.title}`" class="img-fluid position-relative image-custom">
              </div>
              <div class="position-absolute mt-3 ml-2">
                <!-- titulo -->
                <div class="d-flex align-items-center">
                  <h3 class="title-course">{{ course.title }}</h3>
                  <div class="d-none d-sm-block" v-for="(plan, index) in course.plans" :key="index">
                    <span class="mr-1 text-white" style="font-size: .7rem">{{ translatePlans(plan) }}</span>
                  </div>
                </div>
                <!-- descricao -->
                <p class="description-course m-0">{{ course.description }}</p>
                <!-- duracao -->
                <div class="d-none d-sm-flex align-items-center mt-4 ml-2 text-white">
                  <i class="far fa-clock mr-1"></i>
                  <span>{{ `${Math.trunc(courseDuration / 60)}min${Math.trunc(courseDuration % 60)}s` }}</span>
                </div>
                <!-- tags -->
                <div class="d-none d-sm-flex align-items-center text-white ml-1 overflow-auto tags">
                  <div v-for="(tag, index) in course.tags" :key="index" class="tag">
                    <small class="custom-tag">{{ tag }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr>

        <!-- categorias -->
        <div class="row">
          <div class="w-100 mt-4 ml-3 mr-2">
            <div class="d-flex align-items-center">
              <h3 class="mr-4">Categorias</h3>
              <button @click.prevent="$router.push(`/admin/academy/category/create/${$route.params.id}`)"
                      class="btn btn-primary custom-buttom">+ Nova Categoria
              </button>
            </div>
            <!-- array de categorias -->
            <div class="card pl-2 pt-3 pb-2" v-for="(category, index) in course.categories" :key="index">
              <div @click="switchArrayValue(index)" style="cursor: pointer;">
                <div class="d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <label title="Clique para mais informações.">{{ `${index + 1}. ${category.name}` }}</label>
                    <button @click="$router.push(`/admin/academy/category/edit/${category._id}`)"
                            class="btn btn-sm btn-outline-primary custom-buttom-2 ml-3">editar
                    </button>
                    <button @click="deleteCategory(category._id)"
                            class="btn btn-sm btn-outline-danger custom-buttom-2 ml-1">excluir
                    </button>
                  </div>
                  <div class="d-flex mr-0 mr-sm-5">
                    <div v-for="(plan, index2) in category.plans" :key="index2">
                      <p class="text-muted m-0 p-0 mr-2 small" title="Clique para mais informações.">
                        {{ translatePlans(plan) }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="custom-card ml-1">
                <transition name="collapse">
                  <div v-if="arrayCollapse[index]">
                    <!-- array de videos -->
                    <div v-for="(video, generateId) in category.videos" :key="generateId">
                      <div class="w-100 mt-2 mb-2">
                        <div class="d-flex p-0 mb-1" style="height: 80px;">
                          <img :src="`https://img.youtube.com/vi/${getId(video.url)}/0.jpg`" class="img-fluid"
                               alt="thumbnail" :title="video.title">
                          <div class="p-1 ml-1">
                            <label class="m-0">
                              {{ video.title }}
                              <span style="font-size: .7rem; font-weight: normal">{{
                                  `${Math.trunc(video.duration / 60)}min${Math.trunc(video.duration % 60)}s`
                                }}</span>
                            </label>
                            <p class="w-75">{{ video.description }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- spinner esperando carregar -->
    <div :style="spinner ? 'display: none !important' : ''" class="d-flex justify-content-center align-items-center"
         style="height: 90vh">
      <span class="spinner-grow" style="width: 5rem; height: 5rem;" role="status"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'overview_course',
  data() {
    return {
      // manipulacao spinner
      spinner: false,

      image_url: process.env.VUE_APP_URL_IMAGE,

      // objeto de curso
      course: {
        title: null,
        description: null,
        createdBy: null,
        plans: [],
        tags: [],
        img: null,
        active: false,
        categories: [],
        comments: [],
        createdAt: null,
        updatedBy: null,
        lastModify: null,
        progress: 0
      },

      // variavel auxiliar para collapse
      arrayCollapse: []
    }
  },
  computed: {
    courseDuration: function () {
      var duration = 0;
      this.course.categories.forEach(category => duration = duration + category.categoryDuration)
      return duration
    }
  },
  methods: {

    /**
     * Metodo para retornar o videoId
     */
    getId: function (url) {
      return this.$youtube.getIdFromUrl(url)
    },

    /**
     * Metodo que busca o curso na inicialização
     */
    getCourse: async function () {
      await this.$tallos_academy_api.get(`courses/${this.$route.params.id}`)
          .then(response => {
            this.course = response.data;
          })
          .catch(() => {
            this.$toasted.global.defaultError({msg: 'Erro na busca do curso.'})
            console.error('O curso não existe ou não foi possivel encontrá-lo.')
            this.$router.go(-1)
          })
    },

    // /**
    //  * Metodo para excluir o curso
    //  */
    // deleteCourse: async function () {
    //   var result = prompt(`Deseja excluir o curso ${this.course.title}?\nDigite 'sim' para confirmar e 'não' para cancelar.`)
    //   if (result.toLowerCase() === "sim" || result.toLowerCase() === "s") {
    //     await this.$tallos_academy_api.delete(`courses/admin/${this.$route.params.id}`, {data: {deletedBy: this.$store.getters.admin.name}})
    //         .then(response => {
    //           this.$toasted.global.defaultSuccess({msg: response.data.content})
    //           this.$router.push('/admin/academy')
    //         })
    //         .catch(() => {
    //           this.$toasted.global.defaultError({msg: 'Erro ao excluir o curso.'})
    //         })
    //   }
    // },

    /**
     * Metodo para deletar uma categoria
     */
    deleteCategory: async function (id) {
      var result = prompt(`Deseja excluir a categoria?\nDigite 'sim' para confirmar e 'não' para cancelar.`)
      if (result.toLowerCase() === "sim" || result.toLowerCase() === "s") {
        await this.$tallos_academy_api.delete(`categories/${id}`, {data: {deletedBy: this.$store.getters.admin.name}})
            .then(async response => {
              this.$toasted.global.defaultSuccess({msg: response.data.message})
              await this.deleteCategoryInSubs(id)
              await this.getCourse()
            })
            .catch(() => {
              this.$toasted.global.defaultError({msg: 'Erro ao excluir a categoria.'})
            })
      }
    },

    deleteCategoryInSubs: async function(id){
      await this.$tallos_academy_api.delete(`/subscribe/category/d/${id}`, { data: { categoryId: id } })
        .then(response => {
          console.info("[overview] Categoria deletada das subscrições.")
        })
        .catch(error => {
          error.response.data.message.forEach(err => {
            this.$toasted.global.defaultError({msg: err})
          })
        })
    },

    onLoadImg: function () {
      this.spinner = true
    },

    /**
     * Metodo para atualizar o array collapse
     */
    switchArrayValue: function (index) {
      this.arrayCollapse[index] = !this.arrayCollapse[index]
      this.$forceUpdate();
    },


    /**
     * Metodo para inicializar o array collapse
     */
    setArrayCollapse: function () {
      var categoriesLength = this.course.categories.length
      while (categoriesLength--) {
        this.arrayCollapse.push(false)
      }
      this.arrayCollapse[0] = true;
    },

    /**
     * Metodo para traduzir nome dos planos
     */
    translatePlans: function (plan) {
      switch (plan) {
        case 'basic':
          return 'Basic'
        case 'essential':
          return 'Light'
        case 'advanced':
          return 'Basic'
        case 'professional':
          return 'Pro'
      }
    }
  },
  mounted: async function () {
    await this.getCourse()
    this.setArrayCollapse()
    setTimeout(() => {
      if (!this.spinner) {
        this.spinner = true;
        this.$toasted.global.defaultError({msg: 'O curso não foi carregado corretamente. Por favor, recarregue.'})
      }
    }, 5000)
  }
}
</script>

<style scoped>

.image-title {
  max-height: 40vh;
}

.image-title:hover .image-custom {
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  filter: brightness(.9) !important;
}

.image-container {
  overflow: hidden;
  border-radius: 40px 10px;
}

.image-custom {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  object-fit: cover;
  -moz-transform: scale(1.15);
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
  filter: brightness(.5) !important;
  transition: .4s;
}

.title-course {
  padding: 10px;
  color: #fff;
}

.description-course {
  padding-left: 10px;
  width: 75%;
  color: #fff;
}

.custom-buttom {
  font-size: .8rem;
  height: 23px;
  padding: 0rem .7rem;
}

.custom-buttom-2 {
  font-size: .6rem;
  height: 17px;
  padding: 0rem .5rem;
}

.custom-card {
  max-height: 200px;
  overflow: hidden;
  overflow-y: auto;
}

.custom-tag {
  padding: 0px 10px;
  border: 1px solid #fff;
  border-radius: 10px;
  font-size: .6rem;
  margin-left: 4px;
}

.tags{
  min-width: 30em !important;
  max-width: 60em !important;
  margin-top: 1.2em;
}

.tag{
  padding-bottom: 3px;
}

/* collapse */
.collapse-enter-active, .collapse-leave-active {
  transition: opacity .5s;
}

.collapse-enter, .collapse-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */
{
  opacity: 0;
}

@media (max-width: 320px) {
  .title-course {
    font-size: 1rem;
  }

  .description-course {
    font-size: .6rem;
  }
}

::-webkit-scrollbar {
  height: 5px !important;
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #dbdbdb; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b5b5b5; 
}


</style>